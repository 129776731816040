@use '@lendiodevs/sass-variables/src/color-variables' as colors;
@use '@lendiodevs/sass-variables/src/color-palettes';
@import 'node_modules/ag-grid-community/styles/ag-grid.css';
@import 'node_modules/ag-grid-community/styles/ag-theme-material.css';
@import 'node_modules/ag-grid-community/styles/ag-theme-alpine.css';
@import 'node_modules/ag-grid-community/styles/ag-theme-quartz.css';

.ag-theme-quartz, .ag-theme-alpine {
  --ag-foreground-color: #{colors.$lendio-warm-gray-900};
  --ag-background-color: transparent;
  --ag-secondary-foreground-color: #{colors.$lendio-warm-gray-700};
  --ag-data-color: #{colors.$lendio-warm-gray-900};
  --ag-header-background-color: transparent;
  --ag-tooltip-background-color: #{colors.$lendio-warm-gray-800};
  --ag-disabled-foreground-color: #{colors.$lendio-warm-gray-300};
  --ag-subheader-background-color: #{colors.$lendio-white};
  --ag-control-panel-background-color: #{colors.$lendio-white};
  --ag-selected-row-background-color: #{colors.$lendio-warm-gray-10};
  --ag-odd-row-background-color: #{colors.$lendio-white};
  --ag-modal-overlay-background-colo: #{colors.$lendio-white};
  --ag-menu-background-color: #{colors.$lendio-white};
  --ag-panel-background-color: #{colors.$lendio-white};
  --ag-row-hover-color: #{colors.$lendio-warm-gray-10};
  --ag-column-hover-color: transparent;
  --ag-range-selection-border-color: #{colors.$lendio-blue-400};
  --ag-range-selection-border-style: solid;
  --ag-range-selection-background-color: transparent;
  --ag-range-selection-background-color-2: transparent;
  --ag-range-selection-background-color-3: transparent;
  --ag-range-selection-background-color-4: transparent;
  --ag-range-selection-highlight-color: transparent;
  --ag-selected-tab-underline-color: transparent;
  --ag-selected-tab-underline-width: 2px;
  --ag-selected-tab-underline-transition-speed: 200ms;
  --ag-header-cell-hover-background-color: #{colors.$lendio-warm-gray-10};
  --ag-header-cell-moving-background-color: transparent;
  --ag-border-color: transparent;
  --ag-border-radius: 4px;
  --ag-invalid-color: #{colors.$lendio-red-400};
  --ag-input-disabled-border-color: #{colors.$lendio-warm-gray-300};
  --ag-input-disabled-background-color: #{colors.$lendio-white};
  --ag-checkbox-background-color: #{colors.$lendio-white};
  --ag-checkbox-border-radius: 4px;
  --ag-checkbox-checked-color: #{colors.$lendio-blue-400};
  --ag-checkbox-unchecked-color: #{colors.$lendio-warm-gray-700};
  --ag-checkbox-indeterminate-color: #{colors.$lendio-blue-400};
  --ag-input-focus-border-color: #{colors.$lendio-blue-400};
  --ag-icon-size: 14px;
  --ag-icon-font-weight: 500;
  --ag-widget-container-horizontal-padding: 16px;
  --ag-widget-container-vertical-padding: 16px;
  --ag-widget-horizontal-spacing: 16px;
  --ag-widget-vertical-spacing: 16px;
  --ag-cell-horizontal-padding: 16px;
  --ag-cell-widget-spacing: 16px;
  --ag-row-height: 40px;
  --ag-header-height: 40px;
  --ag-list-item-height: 32px;
  --ag-header-column-separator-display: none;
  --ag-header-column-separator-height: 0;
  --ag-header-column-separator-width: 0;
  --ag-header-column-separator-color: transparent;
  --ag-header-column-resize-handle-display: none;
  --ag-column-select-indent-size: 0;
  --ag-set-filter-indent-size: 0;
  --ag-advanced-filter-builder-indent-size: 0;
  --ag-row-group-indent-size: 0;
  --ag-filter-tool-panel-group-indent: 0;
  --ag-menu-min-width: 200px;
  --ag-side-bar-panel-width: 248px;
  --ag-font-family: proxima-nova;
  --ag-font-size: 14px;
  --ag-card-radius: 8px;
  --ag-card-shadow: 0 1px 0 0 #{colors.$lendio-warm-gray-100};
  --ag-popup-shadow: 0 1px 0 0 #{colors.$lendio-warm-gray-100};
  --ag-advanced-filter-join-pill-color: #{colors.$lendio-warm-gray-800};
  --ag-input-focus-box-shadow: none;

  input::-webkit-datetime-edit-month-field:focus,
  input::-webkit-datetime-edit-day-field:focus,
  input::-webkit-datetime-edit-year-field:focus {
    background-color: #{colors.$lendio-warm-gray-10};
  }

  .ag-panel {
    &.ag-default-panel {
      &.ag-dialog {
        &.ag-popup-child {
          background-color: #{colors.$lendio-white};
          min-height: 400px !important;
          transform: translateX(-50%);
        }
      }
    }
  }

  .ag-set-filter-list {
    height: auto;
    padding: 12px 0;
  }

  .ag-row-even,
  .ag-row-odd {
    border-bottom: 1px solid #{colors.$lendio-warm-gray-100};
  }

  &.deal-activities-grid {
      .ag-row-even,
      .ag-row-odd {
      border-bottom: none
    }
    --ag-row-hover-color: none;
  }

  .ag-header-cell {
    font-weight: 700;
    border-bottom: 2px solid #{colors.$lendio-warm-gray-200};
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    .ag-header-cell-comp-wrapper {
      display: block;
    }
  }

  .ag-picker-has-focus {
    box-shadow: none;
    border: 1px solid #{colors.$lendio-blue-400};
  }

  .ag-picker-collapsed {
    border: 1px solid #{colors.$lendio-warm-gray-700};
  }

  .ag-select-list {
    background-color: #{colors.$lendio-white};
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border: 1px solid #{colors.$lendio-warm-gray-700};
  }

  input[class^=ag-][type=text],
  input[class^=ag-][type=number] {
    height: 32px;
    padding: 8px;
    border: 1px solid #{colors.$lendio-warm-gray-700};
    border-radius: 4px;

    &:focus {
      border-bottom: 1px solid;
      border-color: #{colors.$lendio-blue-400};
      border-radius: 4px;
    }

    &:disabled {
      border-bottom: 1px solid;
    }
  }

  .ag-checked {
    border-color:  #{colors.$lendio-blue-400};
  }

  .ag-side-bar {
    min-width: 0;
  }

  .ag-side-buttons {
    width: 0;
    min-width: 0;
    max-width: 0;
  }

  .ag-tool-panel-wrapper {
    transition-timing-function: cubic-bezier(0.2, 0, 0, 1);
    transition-duration: 250ms;
    transition-property: all;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 40px;
      bottom: 0;
      width: 2px;
      background-color: #{colors.$lendio-warm-gray-100};
    }

    &.ag-hidden {
      display: flex !important;
      width: 0 !important;
      border-left-color: transparent;
      &::before {
        width: 0;
      }
    }
  }

  .ag-tool-panel-horizontal-resize {
    display: none;
  }

  .ag-row-animation .ag-row {
    transition: transform 150ms, top 150ms;
  }

  .ag-row-animation .ag-row.ag-after-created {
    transition: transform 150ms, top 150ms, height 150ms;
  }

  .ag-side-bar {
    min-width: 0;
  }

  .ag-side-buttons {
    width: 0;
    min-width: 0;
    max-width: 0;
  }
}

/* Funnel grid styles */
.ag-theme-quartz.funnel-grid {
  .ag-row-hover {
    cursor: pointer;
  }

  .ag-column-first {
    padding-left: 0;
  }

  .ag-selection-checkbox.ag-selection-checkbox {
    display: flex;
    &.ag-invisible {
      display: none;
    }
  }

  .ag-checkbox-input-wrapper,
  .ag-checkbox-input-wrapper.ag-checked,
  .ag-checkbox-input-wrapper.ag-indeterminate {
    height: 34px;
    width: 34px;

    &::after {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: background-color 250ms ease-in-out;
    }

    &:hover {
      &::after {
        background-color: #{colors.$lendio-white};
      }
    }
  }

  .ag-row-selected,
  .ag-row-hover,
  .ag-header-active {
    .ag-checkbox-input-wrapper {
      background-color: #{colors.$lendio-warm-gray-10};

      &:hover {
        &:after {
          background-color: #{colors.$lendio-white};
        }
      }
    }
  }
}

.ag-theme-quartz.deal-activities-grid {
  .ag-row {
    min-height: 68px;
    max-height: 88px;
  }
}

.ag-theme-quartz.lender-tasks-grid {
  --ag-selected-row-background-color: #{colors.$lendio-warm-gray-50};
  --ag-wrapper-border-radius: 0;
  .ag-row {
    min-height: 48px;
  }
}

.ag-theme-quartz.searchable-select-grid {
  --ag-selected-row-background-color: #{colors.$lendio-warm-gray-50};
  --ag-wrapper-border-radius: 0;
  .ag-row {
    height: 32px;
    padding: 0;
    border-bottom: none;
    &:hover {
      cursor: pointer;
    }
  }
  .ag-selection-checkbox {
    margin: 0 4px 0 0;
  }

  .ag-checkbox-input-wrapper,
  .ag-checkbox-input-wrapper.ag-checked,
  .ag-checkbox-input-wrapper.ag-indeterminate {
    height: 24px;
    width: 24px;
  }
}

.ag-theme-quartz.documents-grid {
  .ag-row {
    cursor: default;
  }
  
  .ag-root-wrapper {
    border: none;
  }

  .ag-selection-checkbox {
    margin: 0 8px;
  }

  .ag-header-select-all {
    margin-right: 8px;
  }

  --ag-wrapper-border-radius: 0;
  .filename-header-cell {
    padding-left: 8px;
  }
}
