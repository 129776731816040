@use '@angular/material' as mat;
@use '@lendiodevs/sass-variables/src/color-variables' as colors;
@use 'sass:color';
@import 'app/scss/lendio-agGrid-styles';
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import '@lendiodevs/sass-variables/src/color-palettes';
@import url('https://netdna.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.css');
@import './app/scss/material/slider';
@import 'quill/dist/quill.snow.css';
@import './app/scss/alert';
@import './app/scss/utilities';
@import './app/styles.temp';

// Stop tailwind from interfering with pseudo elements.
*, ::before, ::after {
  border-style: none;
  border-color: initial;
}

// old tailwind overrides Todo refactor tailwind classes once old theme is gone
.text-blue-400 {
  color: #{colors.$lendio-blue-400};
}

@include mat.core();
$lendio-angular-material-theme-primary: mat.m2-define-palette($lendio-blue-palette);
$lendio-angular-material-theme-accent: mat.m2-define-palette($lendio-warm-gray-palette);
$lendio-angular-material-theme-warn: mat.m2-define-palette($lendio-yellow-palette);

@font-face {
  font-family: proxima-nova;
  src: url('https://cdn.lendio.com/fonts/proxima-nova/Proxima-Nova-Regular.woff2');
  font-weight: normal;
}

@font-face {
  font-family: proxima-nova;
  src: url('https://cdn.lendio.com/fonts/proxima-nova/Proxima-Nova-Semibold.woff2');
  font-weight: 500;
}

@font-face {
  font-family: proxima-nova;
  src: url('https://cdn.lendio.com/fonts/proxima-nova/Proxima-Nova-Bold.woff2');
  font-weight: bold;
}

$lendio-typography-config: mat.m2-define-typography-config(
  $font-family: proxima-nova,
  $headline-1: mat.m2-define-typography-level(57px, 64px, 600, $letter-spacing: -0.25px),
  $headline-2: mat.m2-define-typography-level(45px, 52px, 400, $letter-spacing: 0),
  $headline-3: mat.m2-define-typography-level(36px, 44px, 400, $letter-spacing: 0),
  $headline-4: mat.m2-define-typography-level(32px, 40px, 600, $letter-spacing: 0),
  $headline-5: mat.m2-define-typography-level(28px, 36px, 700, $letter-spacing: 0),
  $headline-6: mat.m2-define-typography-level(24px, 32px, 700, $letter-spacing: 0),
  $subtitle-1: mat.m2-define-typography-level(22px, 28px, 700, $letter-spacing: 0),
  $subtitle-2: mat.m2-define-typography-level(18px, 26px, 600, $letter-spacing: 0.15px),
  $body-1: mat.m2-define-typography-level(16px, 24px, 400, $letter-spacing: 0.25px),
  $body-2: mat.m2-define-typography-level(14px, 20px, 400, $letter-spacing: 0.25px),
  $caption: mat.m2-define-typography-level(12px, 16px, 400, $letter-spacing: 0.4px),
  $button: mat.m2-define-typography-level(14px, 20px, 600, $letter-spacing: 0.25px),
);

@include mat.typography-hierarchy($lendio-typography-config);

$lendio-angular-material-theme: mat.m2-define-light-theme((
  color: (
    primary: $lendio-angular-material-theme-primary,
    accent: $lendio-angular-material-theme-accent,
    warn: $lendio-angular-material-theme-warn,
  ),
  typography: $lendio-typography-config,
));

@include mat.all-component-themes($lendio-angular-material-theme);

.lendio-density-1 {
  @include mat.button-density(-1);
  @include mat.icon-button-density(-4);
}

.lendio-density-2 {
  @include mat.button-density(-2);
  @include mat.icon-button-density(-5);
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  font-family: 'proxima-nova, Helvetica, Arial, sans-serif';
  color: #{colors.$lendio-warm-gray-900};
  background: #{colors.$lendio-warm-gray-10};
  margin: 0;
}

html,
body {
  height: 100%;
}

.overlay-loader {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 10;
}

@keyframes spinner {
  to {transform: rotate(360deg);}
}

.spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #2175EB;
  animation: spinner .8s linear infinite;
}

.funnel-chart-tooltip, .ag-chart-tooltip-content {
  text-align: center;
}

.config-form-field {
  margin-top: 15px;

  .mat-mdc-text-field-wrapper {
    max-height: 56px;
  }
}

.filter-menu {
  &.mat-mdc-menu-panel {
    &.mat-mdc-menu-panel {
      min-width: 200px !important;
      max-width: 400px !important;
    }

    .mat-mdc-button .mdc-button__label {
      width: 100%;
      padding-left: 5px;
    }
  }
}

.task-assignments-menu {
  &.mat-mdc-menu-panel {
    max-width: 240px;
    min-width: 200px;
    max-height: 400px;
    overflow-y: auto;
    span.mdc-list-item__primary-text {
        font-size: 14px;
    }
    .mat-mdc-list-base.mat-mdc-list-base .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line {
        height: 30px;
    }
  }
}

.toaster-alerts {
  &.active {
    position: absolute;
    bottom: 40px;
    margin: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

.login-page {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bulk-assign-menu  .mat-mdc-menu-content {
  padding: 0 !important;
}

.mat-mdc-form-field {
  &.lendio-no-hint-wrapper {
    .mat-mdc-form-field-subscript-wrapper {
      height: 0;
    }
  }
}

// TODO: The overrides below need to be replaced when a new version (> 1.25.0)
// of the Angular Material Theme is deployed. There are new utility classes added
// by Luke in the new version of the theme that are not available in the current version
// that should be used instead of these overrides.

// Messages tab overrides
.messages-tab .mat-expansion-panel-body {
  padding-left: 0px;
  padding-right: 0px;
}

// Notify borrower overrides
.mat-accordion.mat-accordion.notify-borrower .mat-expansion-panel.mat-expansion-panel .mat-expansion-panel-content .mat-expansion-panel-body {
  padding-top: inherit;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
}

// Document requirements overrides
.document-requirements {
  .mat-expansion-panel-header,
  .mat-mdc-card-content:first-child,
  .mat-mdc-card-content:last-child {
    padding: 0;
  }

  .mat-expansion-panel-body {
    padding: 0 16px;
  }
  .mat-expansion-indicator {
    margin-right: 16px;
  }
}
