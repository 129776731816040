@use '@lendiodevs/sass-variables/src/color-variables' as lendio;

.bottom-border-cool-gray-100 {
    border-bottom: 1px lendio.$lendio-gray-100 solid;
}

/**
Temporary additions to cover Forge Typography variants until we update to Material 18 which will include
theme customization typography variants for these.
https://material.angular.io/guide/typography
 */
.mat-title-medium {
  font-size: 18px;
  line-height: 26px;
  font-weight: 600;
  letter-spacing: 0.15px;
}

.mat-title-large {
  font-size: 22px;
  line-height: 28px;
  font-weight: 600;
  letter-spacing: 0px;
}

.mat-label-medium {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.25px;
}

.mat-body-medium {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
}
