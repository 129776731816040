.column-grid {
  display: grid;
  grid-auto-flow: column;
}

.vert-centered {
  align-items: center;
  vertical-align: middle;
}

.line-normal {
  line-height: normal;
}
